<template>
  <div id="app">
    <img alt="artwork by Gee Lee" src="./assets/bella-art.jpg" width=300px>
    <Home/>
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
   Home 
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
#app {
  font-family: 'Rubik', sans-serif;
  text-align: center;
  color: #000000;
  margin-top: 60px;
}
</style>
