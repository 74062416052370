<template>
  <div class="home">
    <h1>Bella Mangunsong</h1>
    <p>
      software engineer @ <a href="https://www.canva.com/">Canva</a>
    </p>
    <p>
      connect with me on <a href="https://www.linkedin.com/in/bellamangunsong/">LinkedIn</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}
</style>
